import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as navigationStore} from "./store";

serverLogPlugin(log, "navigation");
log.setLevel("warn");
configureStoreForAuthentication(navigationStore);
configureEmotionCache("navigation");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const BottomNav = () =>
    import(/* webpackChunkName: "BottomNav" */ "./components/BottomNav");

export const TopNav = () =>
    import(/* webpackChunkName: "TopNav" */ "./components/TopNav");
