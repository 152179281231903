/* eslint-disable no-underscore-dangle */
import {combineReducers} from "redux";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        activeBreakpoint,
        alert: () => window._frameStore.getState().alert,
        auth: () => window._frameStore.getState().auth,
        loginHAAPI: () => window._frameStore.getState().loginHAAPI,
        accessToken: () => window._frameStore.getState().accessToken,
        mainNavbar: () => window._frameStore.getState().mainNavbar,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
    });

export default createRootReducer;
